<template>
  <div v-if="Boolean(product)" class="product-card-upsell">
    <div class="product-card-upsell__image-wrapper">
      <ResponsiveImage
        :lazyload="true"
        :mobile-display-size="mobileDisplaySize"
        :desktop-display-size="desktopDisplaySize"
        :mobile-src="imageUrl"
        :desktop-src="imageUrl"
        :alt="product.name"
        class="product-card-upsell__image"
      />
    </div>
    <div class="product-card-upsell__product">
      <p class="product-card-upsell__product__name">
        {{ product.name }}
      </p>
      <div class="product-card-upsell__product__price">
        <p :class="{ 'is-sale-price': product.discounted }">
          {{ product.price }}
        </p>
        <p v-if="product.discounted" class="is-old-price">
          {{ product.priceBeforeDiscount }}
        </p>
      </div>
      <button
        class="product-card-upsell__atc button medium solid small"
        :class="{ loading: loading }"
        :disabled="loading || !product.inStock || cartExceedStock"
        @click="addToCart"
      >
        <template v-if="!loading">
          <span class="product-card-upsell__atc__label label">
            {{
              !product.inStock
                ? $t('Global_Out_Of_Stock')
                : crossSell
                ? $t('Product_Details_Add_To_Order')
                : $t('Product_Details_Add_To_Cart')
            }}
          </span>
        </template>
        <template v-else>
          <span class="product-card-upsell__atc__label label">
            <span class="spinner2" />
          </span>
        </template>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ProductCardUpsell',
  props: {
    productId: {
      type: String,
      required: true,
    },
    position: {
      type: Number,
      required: false,
      default: 1,
    },

    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     */
    mobileDisplaySize: {
      type: String,
      default: '',
    },
    desktopDisplaySize: {
      type: String,
      default: '',
    },
    crossSell: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      cart: 'centra-cart/cart',
    }),
    product() {
      return this.$store.getters['centra-product/getProductById'](
        this.productId
      )
    },
    imageUrl() {
      return this.product?.media?.[0] || ''
    },
    hoverImageUrl() {
      return this.product?.media?.[1] || ''
    },
    cartExceedStock() {
      let cartExceedStock
      if (this.cart && this.cart.items && this.cart.items)
        this.cart.items.forEach((item) => {
          if (this.product.product === item.product)
            cartExceedStock = item.quantity + 1 > this.stock
        })
      return cartExceedStock
    },
    stock() {
      return this.product.sizeOptions[0].quantity
    },
  },

  watch: {
    productId(oldVal) {
      if (oldVal !== '') {
        this.loadProduct()
      }
    },
  },
  serverPrefetch() {
    return this.loadProduct()
  },
  mounted() {
    if (!this.product) {
      this.loadProduct()
    }
  },
  methods: {
    loadProduct() {
      return this.$store.dispatch(
        'centra-product/lookupProducts',
        this.productId
      )
    },
    addToCart() {
      const transformedProduct = this.transformProduct(this.product)
      if (this.product.isOnesize) {
        this.selectedSize = transformedProduct.sizeOptions[0]
      }
      if (this.selectedSize) {
        this.loading = true
        if (this.crossSell) {
          if (typeof this.selectedSize.value === 'string') {
            this.$store
              .dispatch('cross-sell/addToOrder', this.selectedSize.value)
              .then((data) => {
                if (typeof data === 'undefined') {
                  return
                }
                if (data.error_code === 'TIME_LIMIT') {
                  this.$notify({
                    title: this.$t('Cross_Sell_Add_To_Order_Error_Title'),
                    text: this.$t(
                      'Cross_Sell_Add_To_Order_Error_Time_Limit_Exceeded'
                    ),
                    type: 'error',
                    duration: 100000,
                  })
                } else {
                  this.$notify({
                    title: this.$t('Cross_Sell_Add_To_Order_Error_Title'),
                    text: this.$t('Cross_Sell_Add_To_Order_Error_Forbidden'),
                    type: 'error',
                    duration: 100000,
                  })
                }
              })
              .finally(() => {
                this.$notify({
                  title: this.$t('Cross_Sell_Added_To_Cart_Title'),
                  text: this.$t('Cross_Sell_Added_To_Cart_Text'),
                  type: 'success',
                })
                this.loading = false
              })
          }
        } else {
          this.selectedSize.addToCart().finally(() => {
            this.loading = false
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.product-card-upsell {
  display: flex;
  background-color: $white;
  scroll-snap-align: start;
}

.product-card-upsell__image-wrapper {
  @include aspect-ratio(72, 90); // force identical aspect ratio as figma

  flex-shrink: 0;
  width: 12rem;
  margin-right: spacing('xsmall');
}

.product-card-upsell__product {
  display: flex;
  flex-direction: column;
  padding: spacing('xsmall');
  padding-left: 0;
}

.product-card-upsell__product__name,
.product-card-upsell__product__price,
.product-card-upsell__product__price p {
  @include p--medium;

  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
}

.product-card-upsell__product__price {
  display: flex;
  flex-direction: column;

  .is-sale-price {
    color: $blue;
  }

  .is-old-price {
    text-decoration: line-through;
  }
}

.product-card-upsell__atc {
  margin-top: auto;

  &.small {
    .product-card-upsell__atc__label {
      font-weight: 500;
    }
  }
}

.product-card-upsell__atc__label {
  @include p--small;

  padding-left: 1rem;
  padding-right: 1rem;
  white-space: nowrap;
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .product-card-upsell {
    border: 1px solid $grey-light;
  }

  .product-card-upsell__product__name {
    min-width: 15rem;
    margin-bottom: 0.4rem;
  }
}
</style>
