<template>
  <ProductCard
    v-if="product"
    :product="product"
    :position="position"
    :mobile-display-size="mobileDisplaySize"
    :desktop-display-size="desktopDisplaySize"
    :show-cart-icon="showCartIcon"
    :class="[
      'ms--' + mobileSpanColumns,
      'ds--' + desktopSpanColumns
    ]"
  />
</template>

<script>
import ProductCard from '~/components/product-card/ProductCard'

export default {
  name: 'ProductCardCentra',
  components: {
    ProductCard
  },
  props: {
    productId: {
      type: String,
      required: true
    },
    position: {
      type: Number,
      required: false,
      default: 1
    },

    /**
     * Image display sizes for optimized images
     * Can be sent from a parent, ie the grid component
     */
    mobileDisplaySize: {
      type: String,
      default: ''
    },
    desktopDisplaySize: {
      type: String,
      default: ''
    },

    /**
     * Show the cart icon
     */
    showCartIcon: {
      type: Boolean,
      default: false
    },

    mobileSpanColumns: {
      type: String,
      required: false,
      default: '1'
    },
    desktopSpanColumns: {
      type: String,
      required: false,
      default: '1'
    }
  },
  computed: {
    product() {
      return this.$store.getters['centra-product/getProductById'](this.productId)
    },
    isHomePage() {
      return this.$route.path === `/${this.$store.getters['frontend/currentSiteLangSlug']}`
    },
  },
  watch: {
    productId (oldVal) {
      if (oldVal !== "") {
        this.loadProduct()
      }
    }
  },
  serverPrefetch() {
    if (!this.product && !this.isHomePage) {
      // Could have been fetched by parent
      return this.loadProduct()
    }
  },
  mounted() {
    if (!this.product) {
      this.loadProduct()
    }
  },
  methods: {
    loadProduct() {
      return this.$store.dispatch('centra-product/lookupProducts', this.productId)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
